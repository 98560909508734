// generated with @7nohe/openapi-react-query-codegen@1.6.1 

import { UseQueryResult } from "@tanstack/react-query";
import { AccountService, CoreService, IntellifloService, JobsService, OauthService, OrganisationService, PlannrService, StripeService } from "../requests/services.gen";
export type AccountServiceAccountUserRetrieveDefaultResponse = Awaited<ReturnType<typeof AccountService.accountUserRetrieve>>;
export type AccountServiceAccountUserRetrieveQueryResult<TData = AccountServiceAccountUserRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountServiceAccountUserRetrieveKey = "AccountServiceAccountUserRetrieve";
export const UseAccountServiceAccountUserRetrieveKeyFn = (queryKey?: Array<unknown>) => [useAccountServiceAccountUserRetrieveKey, ...(queryKey ?? [])];
export type CoreServiceClientsListDefaultResponse = Awaited<ReturnType<typeof CoreService.clientsList>>;
export type CoreServiceClientsListQueryResult<TData = CoreServiceClientsListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceClientsListKey = "CoreServiceClientsList";
export const UseCoreServiceClientsListKeyFn = ({ name, page, search }: {
  name?: string;
  page?: number;
  search?: string;
} = {}, queryKey?: Array<unknown>) => [useCoreServiceClientsListKey, ...(queryKey ?? [{ name, page, search }])];
export type CoreServiceClientsRetrieveDefaultResponse = Awaited<ReturnType<typeof CoreService.clientsRetrieve>>;
export type CoreServiceClientsRetrieveQueryResult<TData = CoreServiceClientsRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceClientsRetrieveKey = "CoreServiceClientsRetrieve";
export const UseCoreServiceClientsRetrieveKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useCoreServiceClientsRetrieveKey, ...(queryKey ?? [{ id }])];
export type CoreServiceDocumentSetListDefaultResponse = Awaited<ReturnType<typeof CoreService.documentSetList>>;
export type CoreServiceDocumentSetListQueryResult<TData = CoreServiceDocumentSetListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceDocumentSetListKey = "CoreServiceDocumentSetList";
export const UseCoreServiceDocumentSetListKeyFn = ({ executedQuestionLists, page }: {
  executedQuestionLists?: string[];
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useCoreServiceDocumentSetListKey, ...(queryKey ?? [{ executedQuestionLists, page }])];
export type CoreServiceDocumentSetRetrieveDefaultResponse = Awaited<ReturnType<typeof CoreService.documentSetRetrieve>>;
export type CoreServiceDocumentSetRetrieveQueryResult<TData = CoreServiceDocumentSetRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceDocumentSetRetrieveKey = "CoreServiceDocumentSetRetrieve";
export const UseCoreServiceDocumentSetRetrieveKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useCoreServiceDocumentSetRetrieveKey, ...(queryKey ?? [{ id }])];
export type CoreServiceDocumentsListDefaultResponse = Awaited<ReturnType<typeof CoreService.documentsList>>;
export type CoreServiceDocumentsListQueryResult<TData = CoreServiceDocumentsListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceDocumentsListKey = "CoreServiceDocumentsList";
export const UseCoreServiceDocumentsListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useCoreServiceDocumentsListKey, ...(queryKey ?? [{ page }])];
export type CoreServiceDocumentsRetrieveDefaultResponse = Awaited<ReturnType<typeof CoreService.documentsRetrieve>>;
export type CoreServiceDocumentsRetrieveQueryResult<TData = CoreServiceDocumentsRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceDocumentsRetrieveKey = "CoreServiceDocumentsRetrieve";
export const UseCoreServiceDocumentsRetrieveKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useCoreServiceDocumentsRetrieveKey, ...(queryKey ?? [{ id }])];
export type CoreServiceExecutedQuestionItemListDefaultResponse = Awaited<ReturnType<typeof CoreService.executedQuestionItemList>>;
export type CoreServiceExecutedQuestionItemListQueryResult<TData = CoreServiceExecutedQuestionItemListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceExecutedQuestionItemListKey = "CoreServiceExecutedQuestionItemList";
export const UseCoreServiceExecutedQuestionItemListKeyFn = ({ executedQuestionItemList, page, questionItemSection }: {
  executedQuestionItemList?: string;
  page?: number;
  questionItemSection?: string;
} = {}, queryKey?: Array<unknown>) => [useCoreServiceExecutedQuestionItemListKey, ...(queryKey ?? [{ executedQuestionItemList, page, questionItemSection }])];
export type CoreServiceExecutedQuestionItemListListDefaultResponse = Awaited<ReturnType<typeof CoreService.executedQuestionItemListList>>;
export type CoreServiceExecutedQuestionItemListListQueryResult<TData = CoreServiceExecutedQuestionItemListListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceExecutedQuestionItemListListKey = "CoreServiceExecutedQuestionItemListList";
export const UseCoreServiceExecutedQuestionItemListListKeyFn = ({ client, organisation, page }: {
  client?: string;
  organisation?: number;
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useCoreServiceExecutedQuestionItemListListKey, ...(queryKey ?? [{ client, organisation, page }])];
export type CoreServiceExecutedQuestionItemListRetrieveDefaultResponse = Awaited<ReturnType<typeof CoreService.executedQuestionItemListRetrieve>>;
export type CoreServiceExecutedQuestionItemListRetrieveQueryResult<TData = CoreServiceExecutedQuestionItemListRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceExecutedQuestionItemListRetrieveKey = "CoreServiceExecutedQuestionItemListRetrieve";
export const UseCoreServiceExecutedQuestionItemListRetrieveKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useCoreServiceExecutedQuestionItemListRetrieveKey, ...(queryKey ?? [{ id }])];
export type CoreServiceExecutedQuestionItemRetrieveDefaultResponse = Awaited<ReturnType<typeof CoreService.executedQuestionItemRetrieve>>;
export type CoreServiceExecutedQuestionItemRetrieveQueryResult<TData = CoreServiceExecutedQuestionItemRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceExecutedQuestionItemRetrieveKey = "CoreServiceExecutedQuestionItemRetrieve";
export const UseCoreServiceExecutedQuestionItemRetrieveKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useCoreServiceExecutedQuestionItemRetrieveKey, ...(queryKey ?? [{ id }])];
export type CoreServiceGlobalSettingsRetrieveDefaultResponse = Awaited<ReturnType<typeof CoreService.globalSettingsRetrieve>>;
export type CoreServiceGlobalSettingsRetrieveQueryResult<TData = CoreServiceGlobalSettingsRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceGlobalSettingsRetrieveKey = "CoreServiceGlobalSettingsRetrieve";
export const UseCoreServiceGlobalSettingsRetrieveKeyFn = (queryKey?: Array<unknown>) => [useCoreServiceGlobalSettingsRetrieveKey, ...(queryKey ?? [])];
export type CoreServiceQuestionItemListsListDefaultResponse = Awaited<ReturnType<typeof CoreService.questionItemListsList>>;
export type CoreServiceQuestionItemListsListQueryResult<TData = CoreServiceQuestionItemListsListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceQuestionItemListsListKey = "CoreServiceQuestionItemListsList";
export const UseCoreServiceQuestionItemListsListKeyFn = ({ organisation, page, search }: {
  organisation?: number;
  page?: number;
  search?: string;
} = {}, queryKey?: Array<unknown>) => [useCoreServiceQuestionItemListsListKey, ...(queryKey ?? [{ organisation, page, search }])];
export type CoreServiceQuestionItemListsRetrieveDefaultResponse = Awaited<ReturnType<typeof CoreService.questionItemListsRetrieve>>;
export type CoreServiceQuestionItemListsRetrieveQueryResult<TData = CoreServiceQuestionItemListsRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCoreServiceQuestionItemListsRetrieveKey = "CoreServiceQuestionItemListsRetrieve";
export const UseCoreServiceQuestionItemListsRetrieveKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useCoreServiceQuestionItemListsRetrieveKey, ...(queryKey ?? [{ id }])];
export type IntellifloServiceIntellifloCheckTokenRetrieveDefaultResponse = Awaited<ReturnType<typeof IntellifloService.intellifloCheckTokenRetrieve>>;
export type IntellifloServiceIntellifloCheckTokenRetrieveQueryResult<TData = IntellifloServiceIntellifloCheckTokenRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useIntellifloServiceIntellifloCheckTokenRetrieveKey = "IntellifloServiceIntellifloCheckTokenRetrieve";
export const UseIntellifloServiceIntellifloCheckTokenRetrieveKeyFn = (queryKey?: Array<unknown>) => [useIntellifloServiceIntellifloCheckTokenRetrieveKey, ...(queryKey ?? [])];
export type IntellifloServiceIntellifloClientRetrieveDefaultResponse = Awaited<ReturnType<typeof IntellifloService.intellifloClientRetrieve>>;
export type IntellifloServiceIntellifloClientRetrieveQueryResult<TData = IntellifloServiceIntellifloClientRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useIntellifloServiceIntellifloClientRetrieveKey = "IntellifloServiceIntellifloClientRetrieve";
export const UseIntellifloServiceIntellifloClientRetrieveKeyFn = ({ clientId }: {
  clientId: string;
}, queryKey?: Array<unknown>) => [useIntellifloServiceIntellifloClientRetrieveKey, ...(queryKey ?? [{ clientId }])];
export type IntellifloServiceIntellifloPlanPreviewRetrieveDefaultResponse = Awaited<ReturnType<typeof IntellifloService.intellifloPlanPreviewRetrieve>>;
export type IntellifloServiceIntellifloPlanPreviewRetrieveQueryResult<TData = IntellifloServiceIntellifloPlanPreviewRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useIntellifloServiceIntellifloPlanPreviewRetrieveKey = "IntellifloServiceIntellifloPlanPreviewRetrieve";
export const UseIntellifloServiceIntellifloPlanPreviewRetrieveKeyFn = ({ executedQuestionItemListId }: {
  executedQuestionItemListId: string;
}, queryKey?: Array<unknown>) => [useIntellifloServiceIntellifloPlanPreviewRetrieveKey, ...(queryKey ?? [{ executedQuestionItemListId }])];
export type IntellifloServiceIntellifloPlanRetrieveDefaultResponse = Awaited<ReturnType<typeof IntellifloService.intellifloPlanRetrieve>>;
export type IntellifloServiceIntellifloPlanRetrieveQueryResult<TData = IntellifloServiceIntellifloPlanRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useIntellifloServiceIntellifloPlanRetrieveKey = "IntellifloServiceIntellifloPlanRetrieve";
export const UseIntellifloServiceIntellifloPlanRetrieveKeyFn = ({ clientId, planId }: {
  clientId: string;
  planId: string;
}, queryKey?: Array<unknown>) => [useIntellifloServiceIntellifloPlanRetrieveKey, ...(queryKey ?? [{ clientId, planId }])];
export type JobsServiceJobsRetrieveDefaultResponse = Awaited<ReturnType<typeof JobsService.jobsRetrieve>>;
export type JobsServiceJobsRetrieveQueryResult<TData = JobsServiceJobsRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useJobsServiceJobsRetrieveKey = "JobsServiceJobsRetrieve";
export const UseJobsServiceJobsRetrieveKeyFn = ({ jobId }: {
  jobId: string;
}, queryKey?: Array<unknown>) => [useJobsServiceJobsRetrieveKey, ...(queryKey ?? [{ jobId }])];
export type OrganisationServiceOrganisationRetrieveDefaultResponse = Awaited<ReturnType<typeof OrganisationService.organisationRetrieve>>;
export type OrganisationServiceOrganisationRetrieveQueryResult<TData = OrganisationServiceOrganisationRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOrganisationServiceOrganisationRetrieveKey = "OrganisationServiceOrganisationRetrieve";
export const UseOrganisationServiceOrganisationRetrieveKeyFn = (queryKey?: Array<unknown>) => [useOrganisationServiceOrganisationRetrieveKey, ...(queryKey ?? [])];
export type OrganisationServiceOrganisationAccessRequestsListDefaultResponse = Awaited<ReturnType<typeof OrganisationService.organisationAccessRequestsList>>;
export type OrganisationServiceOrganisationAccessRequestsListQueryResult<TData = OrganisationServiceOrganisationAccessRequestsListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOrganisationServiceOrganisationAccessRequestsListKey = "OrganisationServiceOrganisationAccessRequestsList";
export const UseOrganisationServiceOrganisationAccessRequestsListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useOrganisationServiceOrganisationAccessRequestsListKey, ...(queryKey ?? [{ page }])];
export type OrganisationServiceOrganisationMembersListDefaultResponse = Awaited<ReturnType<typeof OrganisationService.organisationMembersList>>;
export type OrganisationServiceOrganisationMembersListQueryResult<TData = OrganisationServiceOrganisationMembersListDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOrganisationServiceOrganisationMembersListKey = "OrganisationServiceOrganisationMembersList";
export const UseOrganisationServiceOrganisationMembersListKeyFn = ({ page }: {
  page?: number;
} = {}, queryKey?: Array<unknown>) => [useOrganisationServiceOrganisationMembersListKey, ...(queryKey ?? [{ page }])];
export type PlannrServicePlannrCheckTokenRetrieveDefaultResponse = Awaited<ReturnType<typeof PlannrService.plannrCheckTokenRetrieve>>;
export type PlannrServicePlannrCheckTokenRetrieveQueryResult<TData = PlannrServicePlannrCheckTokenRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePlannrServicePlannrCheckTokenRetrieveKey = "PlannrServicePlannrCheckTokenRetrieve";
export const UsePlannrServicePlannrCheckTokenRetrieveKeyFn = (queryKey?: Array<unknown>) => [usePlannrServicePlannrCheckTokenRetrieveKey, ...(queryKey ?? [])];
export type PlannrServicePlannrClientRetrieveDefaultResponse = Awaited<ReturnType<typeof PlannrService.plannrClientRetrieve>>;
export type PlannrServicePlannrClientRetrieveQueryResult<TData = PlannrServicePlannrClientRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePlannrServicePlannrClientRetrieveKey = "PlannrServicePlannrClientRetrieve";
export const UsePlannrServicePlannrClientRetrieveKeyFn = ({ accountId, clientId }: {
  accountId: string;
  clientId: string;
}, queryKey?: Array<unknown>) => [usePlannrServicePlannrClientRetrieveKey, ...(queryKey ?? [{ accountId, clientId }])];
export type PlannrServicePlannrPlanPreviewRetrieveDefaultResponse = Awaited<ReturnType<typeof PlannrService.plannrPlanPreviewRetrieve>>;
export type PlannrServicePlannrPlanPreviewRetrieveQueryResult<TData = PlannrServicePlannrPlanPreviewRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePlannrServicePlannrPlanPreviewRetrieveKey = "PlannrServicePlannrPlanPreviewRetrieve";
export const UsePlannrServicePlannrPlanPreviewRetrieveKeyFn = ({ executedQuestionItemListId }: {
  executedQuestionItemListId: string;
}, queryKey?: Array<unknown>) => [usePlannrServicePlannrPlanPreviewRetrieveKey, ...(queryKey ?? [{ executedQuestionItemListId }])];
export type PlannrServicePlannrPlanRetrieveDefaultResponse = Awaited<ReturnType<typeof PlannrService.plannrPlanRetrieve>>;
export type PlannrServicePlannrPlanRetrieveQueryResult<TData = PlannrServicePlannrPlanRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePlannrServicePlannrPlanRetrieveKey = "PlannrServicePlannrPlanRetrieve";
export const UsePlannrServicePlannrPlanRetrieveKeyFn = ({ accountId, planId }: {
  accountId: string;
  planId: string;
}, queryKey?: Array<unknown>) => [usePlannrServicePlannrPlanRetrieveKey, ...(queryKey ?? [{ accountId, planId }])];
export type StripeServiceStripeConfigRetrieveDefaultResponse = Awaited<ReturnType<typeof StripeService.stripeConfigRetrieve>>;
export type StripeServiceStripeConfigRetrieveQueryResult<TData = StripeServiceStripeConfigRetrieveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useStripeServiceStripeConfigRetrieveKey = "StripeServiceStripeConfigRetrieve";
export const UseStripeServiceStripeConfigRetrieveKeyFn = (queryKey?: Array<unknown>) => [useStripeServiceStripeConfigRetrieveKey, ...(queryKey ?? [])];
export type AccountServiceAccountConfirmCreateMutationResult = Awaited<ReturnType<typeof AccountService.accountConfirmCreate>>;
export type AccountServiceAccountPasswordResetCreateMutationResult = Awaited<ReturnType<typeof AccountService.accountPasswordResetCreate>>;
export type AccountServiceAccountPasswordResetConfirmCreateMutationResult = Awaited<ReturnType<typeof AccountService.accountPasswordResetConfirmCreate>>;
export type CoreServiceClientsCreateMutationResult = Awaited<ReturnType<typeof CoreService.clientsCreate>>;
export type CoreServiceDocumentSetCreateMutationResult = Awaited<ReturnType<typeof CoreService.documentSetCreate>>;
export type CoreServiceDocumentsCreateMutationResult = Awaited<ReturnType<typeof CoreService.documentsCreate>>;
export type CoreServiceExecutedQuestionItemListCreateMutationResult = Awaited<ReturnType<typeof CoreService.executedQuestionItemListCreate>>;
export type IntellifloServiceIntellifloPlanMembershipCreateMutationResult = Awaited<ReturnType<typeof IntellifloService.intellifloPlanMembershipCreate>>;
export type IntellifloServiceIntellifloPushCreateMutationResult = Awaited<ReturnType<typeof IntellifloService.intellifloPushCreate>>;
export type OauthServiceOauth2ConvertTokenCreateMutationResult = Awaited<ReturnType<typeof OauthService.oauth2ConvertTokenCreate>>;
export type OauthServiceOauth2RevokeTokenCreateMutationResult = Awaited<ReturnType<typeof OauthService.oauth2RevokeTokenCreate>>;
export type OauthServiceOauth2TokenCreateMutationResult = Awaited<ReturnType<typeof OauthService.oauth2TokenCreate>>;
export type OrganisationServiceOrganisationMembersCreateMutationResult = Awaited<ReturnType<typeof OrganisationService.organisationMembersCreate>>;
export type OrganisationServiceOrganisationRegisterCreateMutationResult = Awaited<ReturnType<typeof OrganisationService.organisationRegisterCreate>>;
export type OrganisationServiceOrganisationRequestPlanUpgradeCreateMutationResult = Awaited<ReturnType<typeof OrganisationService.organisationRequestPlanUpgradeCreate>>;
export type PlannrServicePlannrPlanMembershipCreateMutationResult = Awaited<ReturnType<typeof PlannrService.plannrPlanMembershipCreate>>;
export type PlannrServicePlannrPushCreateMutationResult = Awaited<ReturnType<typeof PlannrService.plannrPushCreate>>;
export type CoreServiceClientsUpdateMutationResult = Awaited<ReturnType<typeof CoreService.clientsUpdate>>;
export type CoreServiceDocumentSetUpdateMutationResult = Awaited<ReturnType<typeof CoreService.documentSetUpdate>>;
export type CoreServiceExecutedQuestionItemListUpdateMutationResult = Awaited<ReturnType<typeof CoreService.executedQuestionItemListUpdate>>;
export type CoreServiceExecutedQuestionItemUpdateMutationResult = Awaited<ReturnType<typeof CoreService.executedQuestionItemUpdate>>;
export type OrganisationServiceOrganisationAccessRequestsUpdateMutationResult = Awaited<ReturnType<typeof OrganisationService.organisationAccessRequestsUpdate>>;
export type CoreServiceClientsPartialUpdateMutationResult = Awaited<ReturnType<typeof CoreService.clientsPartialUpdate>>;
export type CoreServiceDocumentSetPartialUpdateMutationResult = Awaited<ReturnType<typeof CoreService.documentSetPartialUpdate>>;
export type CoreServiceExecutedQuestionItemListPartialUpdateMutationResult = Awaited<ReturnType<typeof CoreService.executedQuestionItemListPartialUpdate>>;
export type CoreServiceExecutedQuestionItemPartialUpdateMutationResult = Awaited<ReturnType<typeof CoreService.executedQuestionItemPartialUpdate>>;
export type OrganisationServiceOrganisationAccessRequestsPartialUpdateMutationResult = Awaited<ReturnType<typeof OrganisationService.organisationAccessRequestsPartialUpdate>>;
export type CoreServiceDocumentSetDestroyMutationResult = Awaited<ReturnType<typeof CoreService.documentSetDestroy>>;
export type CoreServiceDocumentsDestroyMutationResult = Awaited<ReturnType<typeof CoreService.documentsDestroy>>;
export type CoreServiceExecutedQuestionItemListDestroyMutationResult = Awaited<ReturnType<typeof CoreService.executedQuestionItemListDestroy>>;
export type OrganisationServiceOrganisationMembersDestroyMutationResult = Awaited<ReturnType<typeof OrganisationService.organisationMembersDestroy>>;
