import Link from 'next/link';
import { Text } from '../Text';
type BreadCrumbsProps = {
  breadcrumbs: {
    label: string;
    href?: string;
  }[];
};
export const BreadCrumbs = (props: BreadCrumbsProps) => {
  const {
    breadcrumbs
  } = props;
  return <div className="flex items-center pl-2" data-sentry-component="BreadCrumbs" data-sentry-source-file="BreadCrumbs.tsx">
      {breadcrumbs.map((breadcrumb, index) => <div key={`breadcrumb_${index}`}>
          {index + 1 === breadcrumbs.length ? <Text className={index > 0 ? 'ml-4' : ''} fontSize="xxl" fontColor="gray">
              {breadcrumb.label}
            </Text> : <Link href={breadcrumb.href!}>
              <Text fontSize="xxl" fontColor="gray" className="hover:underline">
                {breadcrumb.label}
              </Text>
              <Text className="ml-4" fontSize="xxl" fontColor="gray">
                &gt;
              </Text>
            </Link>}
        </div>)}
    </div>;
};