'use client';

import isEqual from 'lodash/isEqual';
import { useParams, usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';
import { useMixpanel } from '@/hooks/mixpanel';

// IMPORTANT: The first match will be used to track the event
const pathsNames = [{
  path: '/auth/signIn',
  name: 'login',
  authRequired: false
}, {
  path: '/register-organisation',
  name: 'registration',
  authRequired: false
}, {
  path: '/reset-password-initiate',
  name: 'forgot_password',
  authRequired: false
}, {
  path: '/reset-password',
  name: 'change_password_screen_viewed',
  authRequired: false
}, {
  path: '/reloa/client/:client_id',
  name: 'eqi_list',
  authRequired: true
}, {
  path: '/reloa/checklist/:checklist_id',
  name: 'eqi_list',
  authRequired: true
}, {
  path: '/reloa/client/:client_id/checklist/:checklist_id',
  name: 'eqi_list',
  authRequired: true
}, {
  path: '/reloa/client/:client_id/compare',
  name: 'compare_eqi_lists',
  authRequired: true
}, {
  path: '/reloa/new',
  name: 'new_eqi_list',
  authRequired: true
}, {
  path: '/reloa/client/:client_id/new',
  name: 'new_eqi_list',
  authRequired: true
}, {
  path: '/reloa',
  name: 'recent_eqi_lists',
  authRequired: true
}, {
  path: '/settings/organisation',
  name: 'org_management',
  authRequired: true
}];
export const MixpanelTracker = () => {
  const mixpanel = useMixpanel();
  const pathname = usePathname();
  const params = useParams();
  const prevPathnameRef = useRef<string | null>(null);
  const prevParamsRef = useRef<any>(null);
  useEffect(() => {
    if (pathname !== prevPathnameRef.current || !isEqual(prevParamsRef.current, params)) {
      const pathEntry = pathsNames.find(p => {
        // Create a regex pattern by replacing parameters with a regex that matches any string
        const regexPath = p.path.replace(/:\w+/g, '[^/]+');
        const regex = new RegExp(`^${regexPath}$`);
        return regex.test(pathname);
      });
      const eventName = `${pathEntry?.name || pathname}_screen_viewed`;

      // Track the page view when the pathname changes
      mixpanel.trackEvent(eventName, params, undefined, pathEntry?.authRequired);
      prevPathnameRef.current = pathname;
      prevParamsRef.current = params;
    }
  }, [mixpanel, params, pathname]);
  return null; // This component doesn't render anything
};