import(/* webpackMode: "eager" */ "/app/components/Chat/Chat.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/Dropdown.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/FilesUpload.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/layouts/provider-component.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/MessagePopup.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/MixpanelTracker.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/OpenApiProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/PdfViewer.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/PopUp.tsx");
;
import(/* webpackMode: "eager" */ "/app/components/Tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SecureCookiesProvider"] */ "/app/node_modules/next-client-cookies/dist/provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Nunito\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-nunito\"}],\"variableName\":\"nunito\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-perfect-scrollbar/dist/css/styles.css");
;
import(/* webpackMode: "eager" */ "/app/styles/tailwind.css");
