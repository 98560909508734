'use client';

import type { PropertyDict } from 'mixpanel';

import { useAccountServiceAccountUserRetrieve } from '@/openapi/queries';
import { User } from '@/openapi/requests';
import { trackEventServer } from '@/services/mixpanel/trackEvent';

export const useMixpanel = () => {
  const { data: userData } = useAccountServiceAccountUserRetrieve();

  const trackEvent = (
    event: string,
    properties?: PropertyDict,
    user?: User,
    authRequired: boolean = true
  ) => {
    if (!user && !userData && authRequired) {
      return;
    }

    const org = user?.organisation || userData?.organisation;
    trackEventServer(
      event,
      user?.email! || userData?.email!,
      org?.id!,
      org?.name!,
      !userData?.share_info_with_3rd_party,
      properties
    );
  };
  return { trackEvent };
};
