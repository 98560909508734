// generated with @7nohe/openapi-react-query-codegen@1.6.1 

import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AccountService, CoreService, IntellifloService, JobsService, OauthService, OrganisationService, PlannrService, StripeService } from "../requests/services.gen";
import { ClientRequest, ConfirmEmailAndSetPasswordRequest, ConvertTokenRequest, CreateOrganizationMembershipsRequest, DocumentRequest, DocumentSetRequest, ExecutedQuestionItemListUpdateRequest, ExecutedQuestionItemListWriteRequest, ExecutedQuestionItemRequest, IntellifloPlanMembershipRequest, OrganisationAccessRequestRequest, PasswordResetConfirmRequest, PasswordResetRequest, PatchedClientRequest, PatchedDocumentSetRequest, PatchedExecutedQuestionItemListUpdateRequest, PatchedExecutedQuestionItemRequest, PatchedOrganisationAccessRequestRequest, PlannrPlanMembershipRequest, RegisterOrganisationRequest, RevokeTokenRequest, tokenRequest } from "../requests/types.gen";
import * as Common from "./common";
export const useAccountServiceAccountUserRetrieve = <TData = Common.AccountServiceAccountUserRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAccountServiceAccountUserRetrieveKeyFn(queryKey), queryFn: () => AccountService.accountUserRetrieve() as TData, ...options });
export const useCoreServiceClientsList = <TData = Common.CoreServiceClientsListDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ name, page, search }: {
  name?: string;
  page?: number;
  search?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceClientsListKeyFn({ name, page, search }, queryKey), queryFn: () => CoreService.clientsList({ name, page, search }) as TData, ...options });
export const useCoreServiceClientsRetrieve = <TData = Common.CoreServiceClientsRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceClientsRetrieveKeyFn({ id }, queryKey), queryFn: () => CoreService.clientsRetrieve({ id }) as TData, ...options });
export const useCoreServiceDocumentSetList = <TData = Common.CoreServiceDocumentSetListDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ executedQuestionLists, page }: {
  executedQuestionLists?: string[];
  page?: number;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceDocumentSetListKeyFn({ executedQuestionLists, page }, queryKey), queryFn: () => CoreService.documentSetList({ executedQuestionLists, page }) as TData, ...options });
export const useCoreServiceDocumentSetRetrieve = <TData = Common.CoreServiceDocumentSetRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceDocumentSetRetrieveKeyFn({ id }, queryKey), queryFn: () => CoreService.documentSetRetrieve({ id }) as TData, ...options });
export const useCoreServiceDocumentsList = <TData = Common.CoreServiceDocumentsListDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ page }: {
  page?: number;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceDocumentsListKeyFn({ page }, queryKey), queryFn: () => CoreService.documentsList({ page }) as TData, ...options });
export const useCoreServiceDocumentsRetrieve = <TData = Common.CoreServiceDocumentsRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceDocumentsRetrieveKeyFn({ id }, queryKey), queryFn: () => CoreService.documentsRetrieve({ id }) as TData, ...options });
export const useCoreServiceExecutedQuestionItemList = <TData = Common.CoreServiceExecutedQuestionItemListDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ executedQuestionItemList, page, questionItemSection }: {
  executedQuestionItemList?: string;
  page?: number;
  questionItemSection?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceExecutedQuestionItemListKeyFn({ executedQuestionItemList, page, questionItemSection }, queryKey), queryFn: () => CoreService.executedQuestionItemList({ executedQuestionItemList, page, questionItemSection }) as TData, ...options });
export const useCoreServiceExecutedQuestionItemListList = <TData = Common.CoreServiceExecutedQuestionItemListListDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ client, organisation, page }: {
  client?: string;
  organisation?: number;
  page?: number;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceExecutedQuestionItemListListKeyFn({ client, organisation, page }, queryKey), queryFn: () => CoreService.executedQuestionItemListList({ client, organisation, page }) as TData, ...options });
export const useCoreServiceExecutedQuestionItemListRetrieve = <TData = Common.CoreServiceExecutedQuestionItemListRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceExecutedQuestionItemListRetrieveKeyFn({ id }, queryKey), queryFn: () => CoreService.executedQuestionItemListRetrieve({ id }) as TData, ...options });
export const useCoreServiceExecutedQuestionItemRetrieve = <TData = Common.CoreServiceExecutedQuestionItemRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceExecutedQuestionItemRetrieveKeyFn({ id }, queryKey), queryFn: () => CoreService.executedQuestionItemRetrieve({ id }) as TData, ...options });
export const useCoreServiceGlobalSettingsRetrieve = <TData = Common.CoreServiceGlobalSettingsRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceGlobalSettingsRetrieveKeyFn(queryKey), queryFn: () => CoreService.globalSettingsRetrieve() as TData, ...options });
export const useCoreServiceQuestionItemListsList = <TData = Common.CoreServiceQuestionItemListsListDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ organisation, page, search }: {
  organisation?: number;
  page?: number;
  search?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceQuestionItemListsListKeyFn({ organisation, page, search }, queryKey), queryFn: () => CoreService.questionItemListsList({ organisation, page, search }) as TData, ...options });
export const useCoreServiceQuestionItemListsRetrieve = <TData = Common.CoreServiceQuestionItemListsRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseCoreServiceQuestionItemListsRetrieveKeyFn({ id }, queryKey), queryFn: () => CoreService.questionItemListsRetrieve({ id }) as TData, ...options });
export const useIntellifloServiceIntellifloCheckTokenRetrieve = <TData = Common.IntellifloServiceIntellifloCheckTokenRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseIntellifloServiceIntellifloCheckTokenRetrieveKeyFn(queryKey), queryFn: () => IntellifloService.intellifloCheckTokenRetrieve() as TData, ...options });
export const useIntellifloServiceIntellifloClientRetrieve = <TData = Common.IntellifloServiceIntellifloClientRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ clientId }: {
  clientId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseIntellifloServiceIntellifloClientRetrieveKeyFn({ clientId }, queryKey), queryFn: () => IntellifloService.intellifloClientRetrieve({ clientId }) as TData, ...options });
export const useIntellifloServiceIntellifloPlanPreviewRetrieve = <TData = Common.IntellifloServiceIntellifloPlanPreviewRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ executedQuestionItemListId }: {
  executedQuestionItemListId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseIntellifloServiceIntellifloPlanPreviewRetrieveKeyFn({ executedQuestionItemListId }, queryKey), queryFn: () => IntellifloService.intellifloPlanPreviewRetrieve({ executedQuestionItemListId }) as TData, ...options });
export const useIntellifloServiceIntellifloPlanRetrieve = <TData = Common.IntellifloServiceIntellifloPlanRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ clientId, planId }: {
  clientId: string;
  planId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseIntellifloServiceIntellifloPlanRetrieveKeyFn({ clientId, planId }, queryKey), queryFn: () => IntellifloService.intellifloPlanRetrieve({ clientId, planId }) as TData, ...options });
export const useJobsServiceJobsRetrieve = <TData = Common.JobsServiceJobsRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ jobId }: {
  jobId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseJobsServiceJobsRetrieveKeyFn({ jobId }, queryKey), queryFn: () => JobsService.jobsRetrieve({ jobId }) as TData, ...options });
export const useOrganisationServiceOrganisationRetrieve = <TData = Common.OrganisationServiceOrganisationRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOrganisationServiceOrganisationRetrieveKeyFn(queryKey), queryFn: () => OrganisationService.organisationRetrieve() as TData, ...options });
export const useOrganisationServiceOrganisationAccessRequestsList = <TData = Common.OrganisationServiceOrganisationAccessRequestsListDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ page }: {
  page?: number;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOrganisationServiceOrganisationAccessRequestsListKeyFn({ page }, queryKey), queryFn: () => OrganisationService.organisationAccessRequestsList({ page }) as TData, ...options });
export const useOrganisationServiceOrganisationMembersList = <TData = Common.OrganisationServiceOrganisationMembersListDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ page }: {
  page?: number;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseOrganisationServiceOrganisationMembersListKeyFn({ page }, queryKey), queryFn: () => OrganisationService.organisationMembersList({ page }) as TData, ...options });
export const usePlannrServicePlannrCheckTokenRetrieve = <TData = Common.PlannrServicePlannrCheckTokenRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UsePlannrServicePlannrCheckTokenRetrieveKeyFn(queryKey), queryFn: () => PlannrService.plannrCheckTokenRetrieve() as TData, ...options });
export const usePlannrServicePlannrClientRetrieve = <TData = Common.PlannrServicePlannrClientRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ accountId, clientId }: {
  accountId: string;
  clientId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UsePlannrServicePlannrClientRetrieveKeyFn({ accountId, clientId }, queryKey), queryFn: () => PlannrService.plannrClientRetrieve({ accountId, clientId }) as TData, ...options });
export const usePlannrServicePlannrPlanPreviewRetrieve = <TData = Common.PlannrServicePlannrPlanPreviewRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ executedQuestionItemListId }: {
  executedQuestionItemListId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UsePlannrServicePlannrPlanPreviewRetrieveKeyFn({ executedQuestionItemListId }, queryKey), queryFn: () => PlannrService.plannrPlanPreviewRetrieve({ executedQuestionItemListId }) as TData, ...options });
export const usePlannrServicePlannrPlanRetrieve = <TData = Common.PlannrServicePlannrPlanRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ accountId, planId }: {
  accountId: string;
  planId: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UsePlannrServicePlannrPlanRetrieveKeyFn({ accountId, planId }, queryKey), queryFn: () => PlannrService.plannrPlanRetrieve({ accountId, planId }) as TData, ...options });
export const useStripeServiceStripeConfigRetrieve = <TData = Common.StripeServiceStripeConfigRetrieveDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseStripeServiceStripeConfigRetrieveKeyFn(queryKey), queryFn: () => StripeService.stripeConfigRetrieve() as TData, ...options });
export const useAccountServiceAccountConfirmCreate = <TData = Common.AccountServiceAccountConfirmCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ConfirmEmailAndSetPasswordRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ConfirmEmailAndSetPasswordRequest;
}, TContext>({ mutationFn: ({ requestBody }) => AccountService.accountConfirmCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountServiceAccountPasswordResetCreate = <TData = Common.AccountServiceAccountPasswordResetCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: PasswordResetRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: PasswordResetRequest;
}, TContext>({ mutationFn: ({ requestBody }) => AccountService.accountPasswordResetCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useAccountServiceAccountPasswordResetConfirmCreate = <TData = Common.AccountServiceAccountPasswordResetConfirmCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: PasswordResetConfirmRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: PasswordResetConfirmRequest;
}, TContext>({ mutationFn: ({ requestBody }) => AccountService.accountPasswordResetConfirmCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceClientsCreate = <TData = Common.CoreServiceClientsCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ClientRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ClientRequest;
}, TContext>({ mutationFn: ({ requestBody }) => CoreService.clientsCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceDocumentSetCreate = <TData = Common.CoreServiceDocumentSetCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody?: DocumentSetRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody?: DocumentSetRequest;
}, TContext>({ mutationFn: ({ requestBody }) => CoreService.documentSetCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceDocumentsCreate = <TData = Common.CoreServiceDocumentsCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: DocumentRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: DocumentRequest;
}, TContext>({ mutationFn: ({ requestBody }) => CoreService.documentsCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceExecutedQuestionItemListCreate = <TData = Common.CoreServiceExecutedQuestionItemListCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ExecutedQuestionItemListWriteRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ExecutedQuestionItemListWriteRequest;
}, TContext>({ mutationFn: ({ requestBody }) => CoreService.executedQuestionItemListCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useIntellifloServiceIntellifloPlanMembershipCreate = <TData = Common.IntellifloServiceIntellifloPlanMembershipCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: IntellifloPlanMembershipRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: IntellifloPlanMembershipRequest;
}, TContext>({ mutationFn: ({ requestBody }) => IntellifloService.intellifloPlanMembershipCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useIntellifloServiceIntellifloPushCreate = <TData = Common.IntellifloServiceIntellifloPushCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  executedQuestionItemListId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  executedQuestionItemListId: string;
}, TContext>({ mutationFn: ({ executedQuestionItemListId }) => IntellifloService.intellifloPushCreate({ executedQuestionItemListId }) as unknown as Promise<TData>, ...options });
export const useOauthServiceOauth2ConvertTokenCreate = <TData = Common.OauthServiceOauth2ConvertTokenCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: ConvertTokenRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: ConvertTokenRequest;
}, TContext>({ mutationFn: ({ requestBody }) => OauthService.oauth2ConvertTokenCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useOauthServiceOauth2RevokeTokenCreate = <TData = Common.OauthServiceOauth2RevokeTokenCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: RevokeTokenRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: RevokeTokenRequest;
}, TContext>({ mutationFn: ({ requestBody }) => OauthService.oauth2RevokeTokenCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useOauthServiceOauth2TokenCreate = <TData = Common.OauthServiceOauth2TokenCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: tokenRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: tokenRequest;
}, TContext>({ mutationFn: ({ requestBody }) => OauthService.oauth2TokenCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useOrganisationServiceOrganisationMembersCreate = <TData = Common.OrganisationServiceOrganisationMembersCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateOrganizationMembershipsRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateOrganizationMembershipsRequest;
}, TContext>({ mutationFn: ({ requestBody }) => OrganisationService.organisationMembersCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useOrganisationServiceOrganisationRegisterCreate = <TData = Common.OrganisationServiceOrganisationRegisterCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: RegisterOrganisationRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: RegisterOrganisationRequest;
}, TContext>({ mutationFn: ({ requestBody }) => OrganisationService.organisationRegisterCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const useOrganisationServiceOrganisationRequestPlanUpgradeCreate = <TData = Common.OrganisationServiceOrganisationRequestPlanUpgradeCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => OrganisationService.organisationRequestPlanUpgradeCreate() as unknown as Promise<TData>, ...options });
export const usePlannrServicePlannrPlanMembershipCreate = <TData = Common.PlannrServicePlannrPlanMembershipCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: PlannrPlanMembershipRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: PlannrPlanMembershipRequest;
}, TContext>({ mutationFn: ({ requestBody }) => PlannrService.plannrPlanMembershipCreate({ requestBody }) as unknown as Promise<TData>, ...options });
export const usePlannrServicePlannrPushCreate = <TData = Common.PlannrServicePlannrPushCreateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  executedQuestionItemListId: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  executedQuestionItemListId: string;
}, TContext>({ mutationFn: ({ executedQuestionItemListId }) => PlannrService.plannrPushCreate({ executedQuestionItemListId }) as unknown as Promise<TData>, ...options });
export const useCoreServiceClientsUpdate = <TData = Common.CoreServiceClientsUpdateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: ClientRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: ClientRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => CoreService.clientsUpdate({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceDocumentSetUpdate = <TData = Common.CoreServiceDocumentSetUpdateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody?: DocumentSetRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody?: DocumentSetRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => CoreService.documentSetUpdate({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceExecutedQuestionItemListUpdate = <TData = Common.CoreServiceExecutedQuestionItemListUpdateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody?: ExecutedQuestionItemListUpdateRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody?: ExecutedQuestionItemListUpdateRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => CoreService.executedQuestionItemListUpdate({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceExecutedQuestionItemUpdate = <TData = Common.CoreServiceExecutedQuestionItemUpdateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody?: ExecutedQuestionItemRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody?: ExecutedQuestionItemRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => CoreService.executedQuestionItemUpdate({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useOrganisationServiceOrganisationAccessRequestsUpdate = <TData = Common.OrganisationServiceOrganisationAccessRequestsUpdateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody?: OrganisationAccessRequestRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody?: OrganisationAccessRequestRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => OrganisationService.organisationAccessRequestsUpdate({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceClientsPartialUpdate = <TData = Common.CoreServiceClientsPartialUpdateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody?: PatchedClientRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody?: PatchedClientRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => CoreService.clientsPartialUpdate({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceDocumentSetPartialUpdate = <TData = Common.CoreServiceDocumentSetPartialUpdateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody?: PatchedDocumentSetRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody?: PatchedDocumentSetRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => CoreService.documentSetPartialUpdate({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceExecutedQuestionItemListPartialUpdate = <TData = Common.CoreServiceExecutedQuestionItemListPartialUpdateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody?: PatchedExecutedQuestionItemListUpdateRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody?: PatchedExecutedQuestionItemListUpdateRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => CoreService.executedQuestionItemListPartialUpdate({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceExecutedQuestionItemPartialUpdate = <TData = Common.CoreServiceExecutedQuestionItemPartialUpdateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody?: PatchedExecutedQuestionItemRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody?: PatchedExecutedQuestionItemRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => CoreService.executedQuestionItemPartialUpdate({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useOrganisationServiceOrganisationAccessRequestsPartialUpdate = <TData = Common.OrganisationServiceOrganisationAccessRequestsPartialUpdateMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
  requestBody?: PatchedOrganisationAccessRequestRequest;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
  requestBody?: PatchedOrganisationAccessRequestRequest;
}, TContext>({ mutationFn: ({ id, requestBody }) => OrganisationService.organisationAccessRequestsPartialUpdate({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useCoreServiceDocumentSetDestroy = <TData = Common.CoreServiceDocumentSetDestroyMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => CoreService.documentSetDestroy({ id }) as unknown as Promise<TData>, ...options });
export const useCoreServiceDocumentsDestroy = <TData = Common.CoreServiceDocumentsDestroyMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => CoreService.documentsDestroy({ id }) as unknown as Promise<TData>, ...options });
export const useCoreServiceExecutedQuestionItemListDestroy = <TData = Common.CoreServiceExecutedQuestionItemListDestroyMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => CoreService.executedQuestionItemListDestroy({ id }) as unknown as Promise<TData>, ...options });
export const useOrganisationServiceOrganisationMembersDestroy = <TData = Common.OrganisationServiceOrganisationMembersDestroyMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: number;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: number;
}, TContext>({ mutationFn: ({ id }) => OrganisationService.organisationMembersDestroy({ id }) as unknown as Promise<TData>, ...options });
